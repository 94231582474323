<template>
  <module
    ref="module"
    id="requestvps"
    title-icon="fa fa-cubes"
    :title="$t('vpsplans')"
    :use-default-list="false"
  >
    <div slot="global">
      <div v-if="loading" class="text-center">
        <h2>{{ $t('loadingvpsplans') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
      <div v-if="!loading">
        <div class="callout callout-primary" v-for="wl in billplans">
          <h5>{{ $t('vpswhitelabelplansoffered', [wl.fullname]) }}</h5>
          <hr>
          <div v-if="billplans.length < 1" class="row">
            <div class="col-md-12">
              {{ $t('novpsplansoffered') }}
            </div>
          </div>
          <div v-if="billplans.length > 0" class="row">

            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12" v-for="plan in wl.planos">
              <div @click="choosePlan(plan)" class="card pricing" style="cursor: pointer">
                <div class="card-header text-center" style="padding-bottom: 10px">
                  <b>{{ plan.name }}</b>
                </div>
                <hr>
                <div class="card-body pricing">

                  <div>
                    <p><small class="label">{{ $t('settings') }}: </small></p>
                    <p><b><i class="fa fa-star text-warning"></i> {{ split(plan.settings, 0) }}</b></p>
                    <p><b><i class="fa fa-star text-warning"></i> {{ split(plan.settings, 1) }}</b></p>
                    <p><b><i class="fa fa-star text-warning"></i> {{ split(plan.settings, 2) }}</b></p>
                  </div>

                  <div v-if="plan.usercost > 0">
                    <small class="label">{{ $t('cost') }}</small>
                    <p class="cost">
                      <small>$</small>{{ plan.usercost }}<small>/ {{ $t('monthtxt') }}</small>
                    </p>
                  </div>

                  <div v-if="plan.usercost == 0">
                    <small class="label">{{ $t('cost') }}</small>
                    <p class="cost">
                      <small>{{ $t('sponsoredtxt') }}</small>
                    </p>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mt-2" v-if="plan.fixed_location">
                      <small class="label">
                        {{ $t('vpslocation') }}
                      </small>
                      <img class="float-right" :src="`/static/img/flags/${getLocationFlag(plan.location)}.png`">
                    </div>
                  </div>

                </div>
                <hr>
                <div class="card-footer text-center" style="padding-bottom: 10px">
                  <div class="text-center">
                  <span v-if="plan.approval_required" class="text-danger">
                    {{ $t('approvalrequired') }}
                  </span>
                    <span v-if="!plan.approval_required" class="text-primary">
                      {{ $t('noapprovalrequired') }}
                  </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <modal :show.sync="modalvps" headerClasses="justify-content-center">
          <div slot="header">
            <b style="font-size: 2em">
              {{ $t('requestvps') }}
            </b>
          </div>

          <div slot="default" v-if="submitStatus == 'waiting'">
            <div v-if="!submiting && step == 1">
              <div class="row">
                <div class="col-md-12" v-if="mustchoose">
                  <div class="alert alert-warning">
                    <i class="fa fa-exclamation"></i>
                    {{ $t('chooselocationvps') }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5 class="text-center">
                    {{ $t('choosevpslocation') }}
                  </h5>
                </div>
              </div>
              <div class="row location">
                <div class="col-sm-4" v-for="loc in locations">
                  <circle-checkbox-icon
                    icon="fa fa-globe"
                    :title="loc.description"
                    :name="loc.code"
                    :ref="loc.code"
                    @input="locChosen">
                  </circle-checkbox-icon>
                </div>
              </div>
            </div>
            <div v-if="!submiting && step == 2">
              <div class="row">
                <div class="col-md-12" v-if="mustchoose">
                  <div class="alert alert-info">
                    <i class="fa fa-info-circle"></i>
                    {{ $t('requestvpsdetails') }}
                  </div>
                </div>
              </div>
              <div class="row">
                <label v-if="!detailsshort">{{ $t('providesomeinformation') }}</label>
                <label v-if="detailsshort">
                  <i class="fa fa-warning"></i>
                  {{ $t('providesomeinformation') }}
                </label>
                <textarea v-model="details" class="form-control"></textarea>
              </div>
            </div>
            <div v-if="!submiting && step == 3">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="text-center">
                    {{ $t('chooseauser') }}
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <fg-input type="email" placeholder="email@domain.com" v-model="email"
                            v-on:keyup="recipientHandler"></fg-input>
                </div>
                <div class="col-12">
                  <span v-if="userName">
                  {{ $t('usertext') }}: {{ userName }}
                  </span>
                </div>
              </div>

            </div>
            <div v-if="submiting" class="text-center">
              <h2>{{ $t('submitting') }}</h2>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>
          </div>
          <div slot="default" v-if="submitStatus == 'success'">
            <div class="col-md-12 text-center" style="margin-bottom: 20px">
              <i class="fa fa-thumbs-up fa-huge text-success"></i>
            </div>
            <div v-if="choosedPlan.approval_required && !isAdmin" class="col-md-12 text-center">
              <h5>{{ $t('vpsrequestedapprove') }}</h5>
            </div>
            <div v-if="!choosedPlan.approval_required || isAdmin" class="col-md-12 text-center">
              <h5>{{ $t('vpsbeingcreated') }}</h5>
            </div>
          </div>
          <div slot="default" v-if="submitStatus == 'fail'">
            <div class="col-md-12 text-center">
              <i class="fa fa-thumbs-down fa-huge text-danger"></i>
            </div>
            <div class="col-md-12 text-center" style="margin-top: 20px">
              <b>{{ $t(errorMsg) }}</b>
            </div>
          </div>

          <template slot="footer" v-if="submitStatus == 'waiting' && !submiting">
            <div class="right-side">
              <p-button v-if="step === 3 && !userExists" type="info" link v-on:click="findUser()"
                        :disabled="searchu == false">
                {{ $t('searchuser') }}
              </p-button>
              <p-button v-if="step !== 3 || (step === 3 && userExists)" type="success" link
                        v-on:click.prevent="validateAndSubmit()">
                <span>
                  {{ $t('confirm') }}
                </span>
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="left-side">
              <p-button type="danger" v-on:click="cancelOrStepBack()" link>
                {{ $t('cancel') }}
              </p-button>
            </div>
          </template>
          <template slot="footer" v-if="submitStatus == 'waiting' && submiting">
            <div class="right-side">
              <p-button type="default" link>
                <span>{{ $t('confirm') }}</span>
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="left-side">
              <p-button type="default" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>

          <template slot="footer" v-if="submitStatus != 'waiting'">
            <p-button
              :type="submitStatus == 'success' ? 'success' : 'danger'"
              link v-on:click="confirmRequest(submitStatus == 'success')">
                <span>
                  {{ $t('confirm') }}
                </span>
            </p-button>
          </template>
        </modal>
      </div>
    </div>

  </module>
</template>
<script>
import {Modal} from 'src/components/UIComponents'
import {CircleCheckboxIcon} from 'src/components/UIComponents'
import Constants from 'src/assets/constants'
import swal from "sweetalert2";

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  components: {
    Modal,
    CircleCheckboxIcon
  },
  data() {
    return {
      txt: {
        defaultErrorMsg: 'failtorequestvps',
        whitelabel: rules.whitelabelFullName
      },
      eml: user_data.email,
      loading: true,
      loadingFail: false,
      submiting: false,
      modalvps: false,
      approvalRequired: false,
      step: 1,
      detailsshort: false,
      submitStatus: 'waiting',
      mustchoose: false,
      billplans: [],
      locations: [],
      choosedPlan: null,
      choosedLocation: null,
      details: "",
      errorMsg: 'failtorequestvps',
      vpsloc: {},
      email: '',
      searchu: false,
      userName: '',
      isAdmin: rules.isWhitelabelAdmin || rules.isAdmin,
      isHokoAdmin: rules.isAdmin,
      userExists: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      reg_special: /[^A-z\s\d\.\@][\\\^]?/g,
      fixed_location: false,

    }
  },
  methods: {
    loadPlans(response) {
      this.loading = false;
      if (!response.success) {
        this.loadingFail = true;
        return;
      }

      const whitelabels = [];

      response.data.forEach(plano => {
        let needCreate = true;

        whitelabels.forEach(whitelabel => {
          if (whitelabel.whitelabel == plano.whitelabel) {
            needCreate = false;
            whitelabel.planos.push(plano)
          }
        })

        if (needCreate) {
          let planos = []
          planos.push(plano)

          whitelabels.push({
            whitelabel: plano.whitelabel,
            fullname: plano.fullname,
            planos: planos
          })
        }
      })

      this.billplans = whitelabels
    },
    failLoadingPlans() {
      this.loading = false;
    },
    choosePlan(plan) {
      this.fixed_location = !!plan.fixed_location
      const self = this;
      new Promise(resolve => {
        self.step = plan.fixed_location ? 2 : 1
        self.submitStatus = 'waiting';
        self.choosedPlan = plan;
        self.choosedLocation = plan.location;
        self.approvalRequired = plan.approval_required;
        self.detailsshort = false;
        self.modalvps = true;
        resolve()
      }).then(this.cleanAndChoose)
    },
    cleanAndChoose() {
      for (let key of this.locations) {
        this.vpsloc[key.code] = key.code == this.choosedPlan.location
        this.$refs[key.code][0].update(key.code == this.choosedPlan.location)
      }
    },
    loadLocations(response) {
      if (!response.success) {
        return;
      }
      this.locations = response.data;
      response.data.forEach(item => {
        this.vpsloc[item['code']] = false;
      });
    },
    failLoadingLocations() {
      this.loading = false;
    },
    locChosen(e) {
      this.mustchoose = false;
      for (let key in this.vpsloc) {
        this.vpsloc[key] = key == e.name ? e.status : false;
        this.$refs[key][0].update(this.vpsloc[key])
        if (key == e.name) {
          this.choosedLocation = e.status ? key : null;
        }
      }
    },
    serialize() {
      return {
        data: {
          userid: this.eml,
          plan: this.choosedPlan.id,
          location: this.choosedLocation,
          details: this.details
        }
      }
    },
    cancelOrStepBack() {
      if (this.step == 2 && !this.fixed_location) {
        this.step = 1;
        this.detailsshort = false;
        this.details = "";
        this.userExists = false;
      } else if (this.step == 3 && !this.approvalRequired && this.isAdmin) {
        this.userExists = false
        this.email = ""
        this.userName = ""
        this.step = 1;
      } else if (this.step == 3 && rules.isWhitelabelAdmin) {
        this.step = 1;
      } else if (this.step == 3) {
        this.userExists = false
        this.email = ""
        this.userName = ""
        this.step = 2;
        this.details = ""
      } else {
        this.details = ""
        this.userName = ""
        this.userExists = false
        this.modalvps = false;
      }
    },
    validateAndSubmit() {
      if (!this.choosedLocation) {
        this.mustchoose = true;
        return;
      }
      if (rules.isWhitelabelAdmin && this.step == 1) {
        this.details = "WhiteLabelAdmin"
        this.step = 3;
        return;
      }
      if (this.approvalRequired && this.step == 1) {
        this.step = 2;
        return;
      }
      if (this.step == 2 && (!this.details || this.details.length < 4 || this.details.length > 1000)) {
        this.$toast.warning(this.$t('providesomeinformation'))
        this.detailsshort = true;
        return;
      }
      if (this.step == 2 && this.reg_special.test(this.details)) {
        this.$toast.warning(this.$t('not_allowed_special_characters'))
        return;
      }
      if ((this.step == 2 && this.isAdmin) || (this.step == 1 && !this.approvalRequired && this.isAdmin)) {
        this.step = 3;
        this.userExists = false
        return;
      }
      if (this.step == 3 && !this.userExists) {
        this.$toast.warning(this.$t('youmustchooseauser'));
        return;
      }
      this.submiting = true;
      this.$requestVPS(this.serialize())
          .then(this.successHandler, this.errorHandler)
    },
    successHandler(response) {
      this.submiting = false;
      if (!response.success) {
        this.modalvps = false;
        if (response.msg == 'PLAN_NOT_FOUND') {
          this.$toast.warning(this.$t('PLAN_NOT_FOUND'));
        } else if (response.msg == 'USER_HAS_NO_BALANCE') {
          this.$toast.warning(this.$t('USER_HAS_NO_BALANCE'));
        } else if (response.msg == 'USER_HAS_PENDING_APPROVAL') {
          this.$toast.warning(this.$t('USER_HAS_PENDING_APPROVAL'));
        } else {
          this.$toast.error(this.$t('unkownerror'));
        }
        return;
      }
      this.submitStatus = 'success';
    },
    errorHandler() {
      this.$toast.error(this.$t('unkownerror'));
      this.submiting = false;
      this.submitStatus = 'fail';
    },
    confirmRequest(success) {
      this.modalvps = false;
      if (success) {
        window.location = '/vps/main'
      }
    },
    split(settings, idx) {
      return settings.split(" / ")[idx]
    },
    recipientHandler(event) {
      const eml = this.isEmailValid();
      this.searchu = false;
      this.alert_me = false;

      if (eml) {
        if (this.email.length > 0) {
          this.searchu = true;
        }
      }
    },
    isEmailValid() {
      return (this.email == "") ? "" : this.reg.test(this.email);
    },
    preSearchUser() {
      this.findUser(this.email)
    },
    findUser() {
      if (this.email == this.eml && this.isAdmin) {
        swal(this.$t('attentiontxt'), this.$t('useridnotexist'), 'warning')
        return
      }
      this.$checkIfUserExists(this.email).then(this.searchUser, this.userNotfound)
    },
    searchUser(resp) {
      if (resp.success) {
        this.userName = resp.data.first_name + " " + resp.data.last_name
        this.eml = resp.data.userid
        this.userExists = true
      } else {
        this.userNotfound()
      }
    },
    userNotfound() {
      swal(this.$t('attentiontxt'), this.$t('useridnotfound'), 'warning')
    },
    getLocationFlag(row) {
      if (row == 'lon') {
        return 'GB'
      } else if (row == 'ams') {
        return 'NL'
      } else if (row == 'tyo') {
        return 'JP'
      } else if (row == 'fra') {
        return 'DE'
      } else if (row == 'nyc') {
        return 'US'
      } else if (row == 'sin') {
        return 'SG'
      }
    },
  },
  mounted() {
    this.$listWhitelabelBillingPlans({
      active: true,
      isHokoAdmin: rules.isWhitelabelSession ? false : this.isHokoAdmin
    }).then(this.loadPlans, this.failLoadingPlans)

    this.$getVPSLocations()
        .then(this.loadLocations, this.failLoadingLocations)
  }
}
</script>
<style scoped lang="scss">
small.label {
  color: #727272;
}

p.cost {
  text-align: center;
  font-size: 3em;
  color: green;

  small {
    font-size: 0.5em;
  }
}

.pricing {
  hr {
    margin: 0;
  }
}

.pricing:hover {
  background-color: #ffffee;
}
</style>
